import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import { useSelector, useDispatch } from "react-redux"
import * as actions from "../store/actions"
import { useSubscription } from "@apollo/client"
import { onUpdateUser } from "../graphql/subscriptions"
import gql from "graphql-tag"

import Icon from "./Components/Icon"
import Login from "../User/Login"

import { useTranslation, useI18next, Link } from "../i18n"
import siteMap from "../sitemap"
import { sub } from "../../siteUrl"

// Used to calculate delta on previous scroll position
var last_known_scroll_position = 0
var delta_sum = 0

type subMenuData = {
  linkTo: string
  className?: string
  icon?: React.ReactNode
  image?: React.ReactNode
  title?: string
  description?: string
  buttonText?: string
}

type menuData = {
  linkTo: string
  className?: string
  title: string
  onClickDoNothing?: boolean
  showAsActiveAlsoForSubURLs?: boolean
  subMenu?: subMenuData[]
}

const Header = ({ isLanding }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(["translation"])

  //-------------------------------------------------------------------------------------------------------------------------
  // Header on scroll handling

  let ticking = false
  const headerHeight = 68

  // Header fixed/visibility state
  const [fixedHeader, setFixedHeader] = useState(false)
  const [fixedHeaderVisible, setFixedHeaderVisible] = useState(false)
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [mobileNavOpenSubMenu, setMobileNavOpenSubMenu] = useState(-1)

  // Change Header behaviour depending on scroll
  const analyzeScroll = (known_scroll_position) => {
    const delta = known_scroll_position - last_known_scroll_position
    //console.log(known_scroll_position, delta)

    // Below 150px make it fixed and appear/disappear depending on scroll direction (up = appear, down = disappear)
    if (known_scroll_position > headerHeight + 30) {
      if (!fixedHeader) setFixedHeader(true)
      if (delta > 0 && fixedHeaderVisible) {
        if (delta_sum < 15) {
          delta_sum += delta
        } else {
          setFixedHeaderVisible(false)
          delta_sum = 0
        }
      }
      if (delta <= 0 && !fixedHeaderVisible) setFixedHeaderVisible(true)
    }
    // Above 150 make it disappear
    if (known_scroll_position <= headerHeight + 30 && fixedHeaderVisible) {
      setFixedHeaderVisible(false)
    }
    // Above 120 reposition header on top of page
    if (known_scroll_position <= headerHeight && fixedHeader) {
      setFixedHeader(false)
    }

    last_known_scroll_position = known_scroll_position
  }

  // Gestisci l'evendo di scroll della pagina
  const handleScroll = () => {
    const known_scroll_position = window.scrollY

    // Ignora nuovi eventi finchè non termina la gestione attuale
    if (!ticking) {
      window.requestAnimationFrame(function () {
        analyzeScroll(known_scroll_position)
        ticking = false
      })

      ticking = true
    }
  }

  // Aggiungi/Rimuovi un listener allo scroll della pagina
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  //-------------------------------------------------------------------------------------------------------------------------
  // User Data

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  const username: string = useSelector((state) => state.auth.payload.userData?.displayName || state.auth.payload.userData?.email || "")
  const userId = useSelector((state) => state.auth.payload.userData?.userId)
  const avatarUrl = useSelector((state) => state.auth.payload.userData?.avatarUrl?.replace("medium", "small"))
  const isAdmin = useSelector((state) => state.auth.payload.role?.includes("Admin") || state.auth.payload.role?.includes("SuperAdmin"))

  // If user is logged in subscribe to updates relative to user data
  const { error } = useSubscription(gql(onUpdateUser), {
    variables: { id: userId },
    onSubscriptionData: (info) => {
      // If something changes refresh the auth token
      dispatch(actions.refreshAuthToken())
    },
    skip: !isLoggedIn, // do not subscribe if nobody is logged in
    // fetchPolicy: "no-cache",
  })

  const getUserInitials = () => {
    return username.split(" ").map((name) => name.substr(0, 1))
  }

  const loginModalVisible = useSelector((state) => state.layout.loginModalVisibility)

  //-------------------------------------------------------------------------------------------------------------------------
  // Menu Data

  const imgData = useStaticQuery(graphql`
    query {
      autoIbrida: file(relativePath: { eq: "auto-ibrida.medium.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      autoElettrica: file(relativePath: { eq: "auto-elettrica.medium.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      autoIbridaPlugin: file(relativePath: { eq: "auto-ibrida-plugin.medium.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const menu: menuData[] = [
    {
      linkTo: "cars",
      title: t("Le auto"),
      showAsActiveAlsoForSubURLs: true,
      // subMenu: [
      //   {
      //     linkTo: "#",
      //     icon: <Icon name="hybrid" size="xl" />,
      //     title: t("Auto Ibride"),
      //     description: "Un nuovo mondo ricco di <b>servizi aggiuntivi</b> per le auto ibride",
      //     buttonText: "Scopri di più",
      //   },
      //   {
      //     linkTo: "#",
      //     icon: <Icon name="electric" size="xl" />,
      //     title: t("Auto Elettriche"),
      //     description: "Un nuovo mondo ricco di <b>servizi aggiuntivi</b> per le auto elettriche",
      //     buttonText: "Scopri di più",
      //   },
      //   {
      //     linkTo: "#",
      //     icon: <Icon name="hybrid-plugin" size="xl" />,
      //     title: t("Auto Ibride Plug-in"),
      //     description: "Un nuovo mondo ricco di <b>servizi aggiuntivi</b> per le auto ibride plug-in",
      //     buttonText: "Scopri di più",
      //   },
      // ],
    },
    {
      linkTo: "/mondo",
      title: t("I mondi"),
      showAsActiveAlsoForSubURLs: true,
      onClickDoNothing: true,
      subMenu: [
        {
          linkTo: "mondo-ibrido",
          image: <Img fluid={imgData.autoIbrida.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" className="navsub__image" />,
          icon: <Icon name="hybrid" size="l" />,
          title: t("Mondo Ibrido"),
          description:
            "Scopri tutto quello che c’è da sapere in merito alle <b>auto ibride</b>: come funzionano, quanto consumano, e come acquistarle risparmiando con Ecoverso",
          buttonText: "Scopri di più",
        },
        {
          linkTo: "mondo-ibrido-plug-in",
          image: <Img fluid={imgData.autoIbridaPlugin.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" className="navsub__image" />,
          icon: <Icon name="hybrid-plugin" size="l" />,
          title: t("Mondo Ibrido Plug-in"),
          description:
            "Scopri tutto quello che c’è da sapere in merito alle <b>auto ibride plug-in</b>: come funzionano, quanto consumano, e come acquistarle risparmiando con Ecoverso",
          buttonText: "Scopri di più",
        },
        {
          linkTo: "mondo-elettrico",
          image: <Img fluid={imgData.autoElettrica.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" className="navsub__image" />,
          icon: <Icon name="electric" size="l" />,
          title: t("Mondo Elettrico"),
          description:
            "Scopri tutto quello che c’è da sapere in merito alle <b>auto elettriche</b>: come funzionano, quanta autonomia hanno, e come acquistarle risparmiando con Ecoverso",
          buttonText: "Scopri di più",
        },
      ],
    },
    {
      linkTo: "groups",
      title: t("I gruppi"),
    },
    {
      linkTo: "/ecoverso",
      title: t("L'universo"),
      showAsActiveAlsoForSubURLs: true,
      onClickDoNothing: true,
      subMenu: [
        {
          className: "navsub__item--academy",
          linkTo: "ecoverso-academy",
          icon: <Icon source="ecoverso.logos" name="ecoverso-academy" className="navsub__logo" svg />,
          description: "Impara con noi come <b>guidare al meglio un’auto ibrida</b> e come <b>ricaricare facilmente un’auto elettrica</b>",
          buttonText: "Scopri di più",
        },
        {
          className: "navsub__item--race",
          linkTo: "ecoverso-r-ace",
          icon: <Icon source="ecoverso.logos" name="ecoverso-race" className="navsub__logo" svg />,
          description: "La prima gara automobilistica amatoriale in cui <b>vince chi consuma meno</b>. Vieni a divertirti con noi!",
          buttonText: "Scopri di più",
        },
        {
          className: "navsub__item--plus",
          linkTo: "ecoverso-plus",
          icon: <Icon source="ecoverso.logos" name="ecoverso-plus" className="navsub__logo" svg />,
          description: "Un nuovo mondo ricco di <b>servizi aggiuntivi</b> per le auto ibride ed elettriche",
          buttonText: "Scopri di più",
        },
      ],
    },
    {
      linkTo: siteMap.posts.it,
      title: t("Blog"),
    },
    // {
    //   linkTo: "/test/",
    //   className: "hide-below-800",
    //   title: "T",
    // },
  ]

  //-------------------------------------------------------------------------------------------------------------------------
  // Rendering

  return (
    <React.Fragment>
      <header
        className={
          "header " +
          (fixedHeader || mobileNavOpen ? "header--fixed " : "") +
          (fixedHeaderVisible || mobileNavOpen ? "header--fixed--visible " : "") +
          (mobileNavOpen ? "header--fixed--mobile " : "")
        }
      >
        <div className="header__top">
          <div className="header__top__inner">
            {isLanding ? (
              <React.Fragment>
                <div className="header__top__home">
                  <Icon name="logo" className="header__logo" svg />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Link to="/" className="header__top__home" aria-label="Homepage Ecoverso">
                  <Icon name="logo" className="header__logo" svg />
                </Link>

                <nav className="navigation__nav hide-below-750">
                  <ul className="navigation__list">
                    {menu.map((menuItem, i) => (
                      <li className={"navigation__item " + menuItem.className} key={i}>
                        <Link
                          to={menuItem.linkTo}
                          className="navigation__link"
                          partiallyActive={menuItem.showAsActiveAlsoForSubURLs}
                          onClickDoNothing={menuItem.onClickDoNothing}
                          onClick={(e) => {
                            e.stopPropagation()
                            return false
                          }}
                        >
                          {menuItem.title}
                          {menuItem.subMenu ? <Icon name="chevron-down" /> : null}
                        </Link>
                        {menuItem.subMenu ? (
                          <div className="header__secondary navsub navsub--auto">
                            {menuItem.subMenu.map((subMenuItem, j) => (
                              <Link to={subMenuItem.linkTo} className={"navsub__item " + subMenuItem.className} key={j}>
                                {subMenuItem.image ? (
                                  <div className="navsub__image-box">
                                    {subMenuItem.image}
                                    {subMenuItem.icon}
                                  </div>
                                ) : subMenuItem.icon ? (
                                  <div className="navsub__icon">{subMenuItem.icon}</div>
                                ) : null}

                                {subMenuItem.title ? <div className="navsub__title">{subMenuItem.title}</div> : null}
                                {subMenuItem.description ? (
                                  <div className="navsub__desc" dangerouslySetInnerHTML={{ __html: subMenuItem.description }}></div>
                                ) : null}
                                {subMenuItem.buttonText ? <div className="btn btn--ghost">{subMenuItem.buttonText}</div> : null}
                              </Link>
                            ))}
                          </div>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                </nav>

                <nav className="usernav">
                  <ul className="usernav__list">
                    <li className="usernav__item hide-below-750">
                      <Link to="concessionari" className="usernav__link">
                        Concessionari <Icon name="far:question-circle" size="xs" className="hide-below-900" />
                      </Link>
                    </li>
                    <li className="usernav__item">
                      {!isLoggedIn ? (
                        <div className="usernav__link usernav__login" onClick={() => dispatch(actions.setLoginModalVisibility(true))}>
                          {t("Accedi")} <Icon name="user" size="xs" />
                        </div>
                      ) : (
                        <Link to="/app/profile" className={"usernav__link usernav__avatar" + (isAdmin ? " usernav__admin" : "")}>
                          <div className="usernav_photo">{avatarUrl ? <img src={avatarUrl} /> : getUserInitials()}</div>
                        </Link>
                      )}
                    </li>
                    <li
                      className={"usernav__item  mobilenav__button " + (mobileNavOpen ? "mobilenav__button-active" : "hide-above-750")}
                      onClick={() => {
                        setMobileNavOpen(!mobileNavOpen)
                        setMobileNavOpenSubMenu(-1)
                      }}
                    >
                      <div className="mobilenav__icon" />
                    </li>
                  </ul>
                </nav>
              </React.Fragment>
            )}
          </div>
        </div>
      </header>
      {fixedHeader ? (
        <header className="header">
          <div className="header__top">&nbsp;</div>
        </header>
      ) : null}
      {loginModalVisible ? <Login onClose={() => dispatch(actions.setLoginModalVisibility(false))} /> : null}
      {mobileNavOpen ? (
        <div className="mobilenav__modal">
          <div className="mobilenav__modal__content">
            <ul className="mobilenav__list">
              {menu.map((menuItem, i) => (
                <React.Fragment>
                  <li className={"mobilenav__item " + menuItem.className} key={i}>
                    <Link
                      to={menuItem.linkTo}
                      className="mobilenav__item__link"
                      partiallyActive={menuItem.showAsActiveAlsoForSubURLs}
                      onClickDoNothing={menuItem.onClickDoNothing}
                      onClick={(e) => {
                        if (menuItem.subMenu) {
                          setMobileNavOpenSubMenu(mobileNavOpenSubMenu == i ? -1 : i)
                        }
                      }}
                    >
                      {menuItem.title}
                      {menuItem.subMenu ? mobileNavOpenSubMenu == i ? <Icon name="minus-circle" /> : <Icon name="plus-circle" /> : null}
                    </Link>
                  </li>
                  {menuItem.subMenu && mobileNavOpenSubMenu == i ? (
                    <ul className="mobilenav__list">
                      {menuItem.subMenu.map((subMenuItem, j) => (
                        <li className="mobilenav__submenu" key={j}>
                          <Link to={subMenuItem.linkTo} className={"mobilenav__item__link " + subMenuItem.className}>
                            {subMenuItem.image ? (
                              <div className="mobilenav__submenu__image-box">{subMenuItem.image}</div>
                            ) : subMenuItem.icon ? (
                              <div className="mobilenav__submenu__icon">{subMenuItem.icon}</div>
                            ) : null}

                            {subMenuItem.title ? <div className="mobilenav__submenu__title">{subMenuItem.title}</div> : null}
                            <div className="mobilenav__submenu__chevron">
                              <Icon name="chevron-right" />
                            </div>
                          </Link>
                        </li>
                      ))}{" "}
                    </ul>
                  ) : null}
                </React.Fragment>
              ))}
            </ul>
            <Link to="concessionari" className="btn mobilenav__btn">
              Concessionari &nbsp; <Icon name="far:question-circle" size="xs" />
            </Link>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default Header
