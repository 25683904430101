import React, { ReactNode, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import * as actions from "../../store/actions"

import { notification } from "antd"

import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  InfoCircleTwoTone,
  SmileOutlined,
  WarningTwoTone,
} from "@ant-design/icons"

export interface INotification {
  key?: string
  message: string
  description?: string // default 4.5s
  icon?: ReactNode
  variant?: "SuccessIcon" | "DangerIcon" | "WarningIcon" | "InfoIcon" | "SmileIcon"
  duration?: number
  onClose?: () => void
  onClick?: () => void
}

export const SuccessIcon = () => <CheckCircleTwoTone twoToneColor="#52c41a" />

export const WarningIcon = () => <WarningTwoTone twoToneColor="#ff6600" />

export const DangerIcon = () => <ExclamationCircleTwoTone twoToneColor="#990000" />

export const InfoIcon = () => <InfoCircleTwoTone />

export const SmileIcon = () => <SmileOutlined style={{ color: "#108ee9" }} />

const Notifications = () => {
  // Show notifications
  const notifications = useSelector((state) => state.layout.notifications)
  const dispatch = useDispatch()
  useEffect(() => {
    if (notifications.length) {
      notifications.forEach((theNotification) => {
        // Set Icon if needed
        switch (theNotification.variant) {
          case "SuccessIcon":
            theNotification.icon = <SuccessIcon />
            break
          case "WarningIcon":
            theNotification.icon = <WarningIcon />
            break
          case "DangerIcon":
            theNotification.icon = <DangerIcon />
            break
          case "InfoIcon":
            theNotification.icon = <InfoIcon />
            break
          case "SmileIcon":
            theNotification.icon = <SmileIcon />
            break
        }
        notification.open(theNotification)
      })
      // Empty notifications array after creating them
      dispatch(actions.notificationEmpty())
    }
  }, [notifications])

  return null
}

export default Notifications
