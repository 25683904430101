/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($id: String) {
    onUpdateUser(id: $id) {
      id
      username
      identityId
      idAffiliation
      idOrganization
      Organization {
        id
        name
        organizationType
        isLocation
        isPlaceHolder
        country
        state
        city
        zipCode
        address1
        VATNumber
        SDICode
        PECEmail
        brands
        notificationsEmail
        notifyOnEachSell
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      email
      emailVerificationCode
      emailVerificationDate
      emailVerified
      displayName
      firstName
      lastName
      gender
      birthDate
      birthPlace
      roles
      mobile
      mobileVerificationCode
      mobileVerificationDate
      mobileVerified
      country
      state
      city
      zipCode
      address1
      taxCode
      avatarUrl
      declaredOrigin
      discountCode
      acptProfiling
      updtProfiling
      acptMarketing
      updtMarketing
      acptNewsletter
      updtNewsletter
      blockProcessing
      updtProcessing
      acptStatute
      updtStatute
      subscribedUntil
      subscribedSince
      stripeCustomerId
      firstLoginDate
      lastLoginDate
      UserInBuyingGroups {
        id
        idBuyingGroup
        idUser
        registrationDate
        participationDate
        choosingDate
        notInterestInBuying
        confirmationDate
        definitelyNotInterestedInBuying
        lastEmaiNotificationDate
        disableNotifications
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      ChosedVariants {
        id
        idBuyingGroup
        idUser
        idVariant
        idReseller
        quantity
        dateChosed
        userChoosingCustomValues
        dateConfirmed
        userConfirmationCustomValues
        dateFinalization
        resellerSellingCustomValues
        totalSoldAmount
        dateCanceled
        cancellationPhase
        cancellationReason
        dateDelivered
        dateBilled
        commisionAmount
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Payments {
        id
        idUser
        stripeSessionId
        paymentTime
        amount_subtotal
        amount_total
        currency
        payment_status
        mode
        stripeCustomerId
        stripeSubscriptionId
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Subscriptions {
        id
        idUser
        stripeSubscriptionId
        stripeCustomerId
        startDate
        endDate
        status
        cancel_at_period_end
        canceledAt
        stripeProductId
        stripePriceId
        amount
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
      toBeDeletedAfter
    }
  }
`;
export const onCreateEmailEvent = /* GraphQL */ `
  subscription OnCreateEmailEvent {
    onCreateEmailEvent {
      id
      client_id
      address
      message_id
      type
      date
      feedback
      subject
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEmailEvent = /* GraphQL */ `
  subscription OnUpdateEmailEvent {
    onUpdateEmailEvent {
      id
      client_id
      address
      message_id
      type
      date
      feedback
      subject
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEmailEvent = /* GraphQL */ `
  subscription OnDeleteEmailEvent {
    onDeleteEmailEvent {
      id
      client_id
      address
      message_id
      type
      date
      feedback
      subject
      createdAt
      updatedAt
    }
  }
`;
