import React from "react"
import { useSelector, useDispatch } from "react-redux"
import ClientSideSuspense from "./ClientSideSuspense"

import Header from "./Header"
import Notifications from "./Notifications"
import WelcomeWizard from "../User/WelcomeWizard"

import { BackTop } from "antd"

import "antd/dist/antd.css"
import "../sass/main.scss"
import Footer from "./Footer"
import Icon from "./Components/Icon"
import Loading from "./Components/Loading"

import { useTranslation, Helmet } from "../i18n"
import { SeoContextProvider, StructuredData, sdEcoversoOrganization, addContext } from "../seo"

type Props = {
  children: any
  className?: string
  isLanding?: boolean
}

const GridLayout: React.FC<Props> = ({ children, className, isLanding }) => {
  const isLoading = useSelector((state) => state.layout.loading)

  // const openWelcomeWizard = useSelector(
  //   (state) =>
  //     state.auth.isLoggedIn &&
  //     (state.auth.payload?.userData?.registrationComplete == false ||
  //       state.auth.payload?.userData?.registrationComplete == "false")
  // )

  return (
    <React.Fragment>
      <SeoContextProvider>
        <StructuredData data={addContext(sdEcoversoOrganization)} />
        <Helmet />
        <Loading active={isLoading} fixed>
          <div className={"main_grid " + className}>
            <BackTop>
              <div className="backtop">
                <Icon name="arrow-up" />
              </div>
            </BackTop>
            <Header isLanding={isLanding} />
            {children}
            <Footer isLanding={isLanding} />
          </div>
          {/* {openWelcomeWizard ? <WelcomeWizard /> : null} */}
          <Notifications />
        </Loading>
      </SeoContextProvider>
    </React.Fragment>
  )
}

export default GridLayout
