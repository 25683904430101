import React from "react"
import { useTranslation, Link, FormatDate } from "../i18n"
import Icon from "./Components/Icon"
import sitemap from "../sitemap"

const Footer = ({ isLanding }) => {
  const { t } = useTranslation([])

  return (
    <footer className={"row row--full footer" + (isLanding ? " pt-none" : "")}>
      {!isLanding ? (
        <React.Fragment>
          <div className="row row--inner footer__menu-primary row--gap-small">
            <div className="col col--5 footer__menu-primary__left">
              <ul>
                <li>
                  <Link to="chi-siamo">Chi siamo</Link>
                </li>
                <li>
                  <Link to={sitemap.press.it}>{t("Rassegna Stampa")}</Link>
                </li>
                <li>
                  <Link to={sitemap.testimonials.it}>{t("Testimonianze")}</Link>
                </li>
              </ul>
            </div>
            <div className="col col--f2 footer__menu-primary__center hide-below-600">
              <Link to="/" className="hide-below-700" aria-label="Homepage Ecoverso">
                <Icon name="logo" className="footer__logo" svg />
              </Link>
            </div>
            <div className="col col--5 footer__menu-primary__right">
              <ul>
                <li>
                  <Link to={sitemap.faq.it}>FAQ</Link>
                </li>
                <li>
                  <Link to={sitemap.posts.it}>Blog</Link>
                </li>
                <li>
                  <Link to="contatti">Contatti</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="row row--inner footer__separator"></div>
          <div className="row row--inner footer__menu-social">
            <ul className="col col--12">
              <li>
                <Link to={sitemap.facebook.it} aria-label="Facebook - Ecoverso" target="_blank">
                  <Icon name="facebook" className="footer__icon" />
                </Link>
              </li>
              <li>
                <Link to={sitemap.instagram.it} aria-label="Instagram - Ecoverso" target="_blank">
                  <Icon name="instagram" />
                </Link>
              </li>
              <li>
                <Link to={sitemap.youtube.it} aria-label="Youtube - Ecoverso" target="_blank">
                  <Icon name="youtube" />
                </Link>
              </li>
              <li>
                <Link to={sitemap.twitter.it} aria-label="Twitter - Ecoverso" target="_blank">
                  <Icon name="twitter" />
                </Link>
              </li>
            </ul>
          </div>
        </React.Fragment>
      ) : null}
      <div className="row row--inner footer__menu-secondary row--no-grid">
        &copy; 2010 - <FormatDate date={new Date()} format="yyyy" /> &mdash;
        <Link to="privacy">Privacy Policy</Link>/<Link to="termini-e-condizioni">Termini e condizioni</Link>/
        {/* <Link to="#">Sitemap</Link>/ */}
        <Link to="cookie-policy">Cookie Policy</Link>
      </div>
    </footer>
  )
}

export default Footer
