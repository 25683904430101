import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "../i18n"
import Img from "gatsby-image/withIEPolyfill"
import { Icon, Tabs, Overlay } from "../Layout/Components"

import FormGenerator from "../Layout/Forms/FormGenerator"
import { useSelector, useDispatch } from "react-redux"
import * as actions from "../store/actions"
import * as actionTypes from "../store/actions/actionTypes"

import {
  email,
  firstName,
  lastName,
  declaredOrigin,
  discountCodeInfo,
  discountCode,
  readPrivacy,
  newsletterInfo,
  acptNewsletter,
  marketingInfo,
  acptMarketing,
  profilingInfo,
  acptProfiling,
  loginCode,
} from "../forms/fields/user"

import { useTranslation } from "../i18n"
import { FormPropertiesData } from "../Layout/Forms/FormDataType"
import { valueFromASTUntyped } from "graphql-compose/lib/graphql"

let lastFormSetSubmitting = null
let challengeFormSetValues = null

const Login = (props: { onClose: Function }) => {
  const [t] = useTranslation(["errors"])
  const dispatch = useDispatch()
  const data = useStaticQuery(graphql`
    query {
      people: file(relativePath: { eq: "ecoverso.people.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const signUpForm: FormPropertiesData = {
    row1: {
      type: "row",
      properties: {
        firstName: firstName,
        lastName: lastName,
      },
    },
    email,
    declaredOrigin,
    discountCodeInfo,
    discountCode,
    readPrivacy,
    newsletterInfo,
    acptNewsletter,
    marketingInfo,
    acptMarketing,
    profilingInfo,
    acptProfiling,
  }

  const signInForm: FormPropertiesData = {
    email,
    readPrivacy,
    text: {
      type: "component",
      component: (
        <p className="mb-m">
          Hai dimenticato la mail? <Link to="#">Contatta il servizio clienti</Link>
        </p>
      ),
    },
  }

  const challengeForm: FormPropertiesData = {
    loginCode,
    text: {
      type: "component",
      component: (
        <p className="mb-m">
          <a
            onClick={() => {
              onSubmitSignIn({ email: lastAuthEmail })
              dispatch(actions.notificationAdd({ message: "Codice inviato nuovamente" }))
            }}
          >
            Invia di nuovo il codice
          </a>
        </p>
      ),
    },
  }

  const [activeForm, setActiveForm] = useState(0)
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  const lastAuthStatus = useSelector((state) => state.auth.lastStatus)
  const lastAuthEmail = useSelector((state) => state.auth.lastLoginEmail)
  const authErrorMessage = useSelector((state) => state.auth.errorMessage)

  // Submit Registration
  const onSubmitSignUp = (values, setSubmitting) => {
    lastFormSetSubmitting = setSubmitting
    values.email = values.email.toLowerCase() // Avoid cognito case sensitiveness
    console.log(values)
    dispatch(
      actions.passwordlessSignUp(values.email, {
        email: values.email,
        given_name: values.firstName,
        family_name: values.lastName,
        "custom:declaredOrigin": values.declaredOrigin,
        "custom:discountCode": values.discountCode,
        "custom:acptNewsletter": values.acptNewsletter ? "1" : "0",
        "custom:acptMarketing": values.acptMarketing ? "1" : "0",
        "custom:acptProfiling": values.acptProfiling ? "1" : "0",
      })
    )
  }

  // Submit Login
  const onSubmitSignIn = (values, setSubmitting?) => {
    lastFormSetSubmitting = setSubmitting
    values.email = values.email.toLowerCase() // Avoid cognito case sensitiveness
    dispatch(actions.passwordlessSignIn(values.email, true))
  }

  // Submit Challenge
  const onSubmitChallenge = (values, setSubmitting) => {
    lastFormSetSubmitting = setSubmitting
    dispatch(actions.passwordlessChallenge(values.loginCode, true))
  }

  // Reset form submitting state
  useEffect(() => {
    // Rimuovi "loading" da form submit
    if (
      lastAuthStatus != actionTypes.AUTH_START &&
      lastAuthStatus != actionTypes.AUTH_CHALLENGE_START &&
      lastAuthStatus != actionTypes.REGISTRATION_START &&
      lastFormSetSubmitting
    ) {
      lastFormSetSubmitting(false)
      lastFormSetSubmitting = null
    }
    // Azzera codice in caso di fallimento
    if ((lastAuthStatus == actionTypes.AUTH_CHALLENGE_FAIL || lastAuthStatus == actionTypes.AUTH_FAIL) && challengeFormSetValues) {
      challengeFormSetValues({ loginCode: "" }, true)
    }

    // Se auth challenge, cambia form (finale registrazione)
    if (lastAuthStatus == actionTypes.AUTH_CHALLENGE) setActiveForm(0)
  }, [lastAuthStatus])

  // Reset auth process if when the modal is opened the last action was failed
  useEffect(() => {
    if (lastAuthStatus == actionTypes.AUTH_FAIL || lastAuthStatus == actionTypes.REGISTRATION_FAIL) {
      dispatch(actions.authReset())
    }
  }, [activeForm])

  // If a user is logged in avoid showing login/register modal
  if (isLoggedIn) return null

  return (
    <React.Fragment>
      <Overlay />
      <div className="login__foreground">
        <div className="login__modal">
          <Img fluid={data.people.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" className="login__modal-image" />
          <div className="login__modal-content">
            <Tabs tabs={["Accedi", "Registrati"]} className="login__modal-tabs" onChange={(id) => setActiveForm(id)} selected={activeForm} />

            {activeForm == 0 ? (
              <React.Fragment>
                {lastAuthStatus != actionTypes.AUTH_CHALLENGE &&
                lastAuthStatus != actionTypes.AUTH_CHALLENGE_START &&
                lastAuthStatus != actionTypes.AUTH_CHALLENGE_FAIL ? (
                  <React.Fragment>
                    <p>Inserisci l'email che hai usato durante la tua registrazione</p>
                    <FormGenerator
                      className="btn--fullwidth"
                      properties={signInForm}
                      values={null}
                      hideCancelButton
                      submitButtonText="Accedi"
                      onSubmit={onSubmitSignIn}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p>
                      Per accedere digita il codice che abbiamo inviato a: <b>{lastAuthEmail}</b>
                    </p>
                    <FormGenerator
                      className="btn--fullwidth"
                      properties={challengeForm}
                      values={{ loginCode: "" }}
                      hideCancelButton
                      submitButtonText="Verifica Codice"
                      onSubmit={onSubmitChallenge}
                      bindSetValues={(callback) => {
                        challengeFormSetValues = callback
                      }}
                    />
                  </React.Fragment>
                )}

                {lastAuthStatus == actionTypes.AUTH_FAIL || lastAuthStatus == actionTypes.AUTH_CHALLENGE_FAIL ? (
                  <div className="login__modal-error">{t(authErrorMessage)}</div>
                ) : null}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <React.Fragment>
                  <FormGenerator
                    className="btn--fullwidth"
                    properties={signUpForm}
                    values={null}
                    hideCancelButton
                    submitButtonText="Registrati"
                    onSubmit={onSubmitSignUp}
                  />
                </React.Fragment>

                {lastAuthStatus == actionTypes.REGISTRATION_FAIL ? <div className="login__modal-error">{t(authErrorMessage)}</div> : null}
              </React.Fragment>
            )}
          </div>
          <div className="login__modal-close" onClick={() => props.onClose()}>
            <Icon name="x" />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Login
